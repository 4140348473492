<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Customer</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ form.customerName }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>NPWP</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ form.npwp }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Type<span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ form.type }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Date<span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ form.date }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Number</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ form.number }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Invoice#<span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="9" lg="9">
              <span>{{ form.invoices ? form.invoices.map(x => x.number).join(",") : "" }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-card outlined>
            <v-card-title class="py-0">Total</v-card-title>
            <v-card-text class="text-right mt-3">
              <span>{{ formatPrice(form.totalAmount) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card outlined>
            <v-card-title class="py-0">PPH</v-card-title>
            <v-card-text class="text-right mt-3">
              <span>{{ formatPrice(form.pphAmount) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "detail-pph-4-verse-2",
  props: {
    form: Object,
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
